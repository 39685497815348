import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"

import cobertura_analisis from '../../../static/img/irstrat/cobertura_analisis.png'
import free_float from '../../../static/img/irstrat/free_float.png'
import planta_plata from '../../../static/img/irstrat/estrategia11.jpg'

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Fundamentales de R.I."/>
            <Banner title="Fundamentales de R.I."/>

            <section className="offer_area_two propuesta">
                <div className="container">
                    <div className="row strategy_info align-items-center">
                        <div className="col-lg-12">
                            <div className="ex_content">
                                <h2>Beneficios tangibles</h2>
                                <span className="sec_border"></span>
                                <p>Está demostrado que una función eficiente de Relaciones con Inversionistas (RI)
                                    genera una mayor valuación en los valores de una empresa, al menos, entre un 10%-15%
                                    (de acuerdo con el NIRI, “National Investor Relations Institute”).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="offer_area_two propuesta bg-white">
                <div className="container">
                    <div className="row strategy_info align-items-center">
                        <div className="col-lg-12">
                            <div className="ex_content">
                                <p>Una valuación incremental de 15% se generaría, por ejemplo, si una emisora cambia de
                                    un programa básico de RI (enfocado sólo a preparación de reportes y
                                    cumplimiento regulatorio, sin una difusión apropiada de su tesis de inversión), a un
                                    programa premier de RI (enfocado en identidad e imagen, mensaje estratégico e
                                    inteligencia y lobbying). Ahora, supongamos que estamos hablando de una emisora
                                    small-cap (capitalización de mercado de US$1 bill.), que, aprovechando la valuación
                                    incremental, ejecutará un exitoso go-to-market, es decir una colocación secundaria
                                    del 20% de su capital, por lo tanto, derivado de este positivo impulso, esta empresa
                                    recibiría los siguientes beneficios:</p>

                                <p><strong>Recursos líquidos por US$30 mills. en el 20% colocado; y, mark-to-market por
                                    otros US$120 mills., en la valuación del 80% restante del capital.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="propuesta">
                <div className="container">
                    <h3 className="text-center">Solución efectiva</h3>
                    <div className="row strategy_info align-items-center mb-5">
                        <div className="col-lg-5">
                            <div className="ex_content">
                                <img className="wow rotateInDownLeft" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                     src={planta_plata} alt={" "}/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <p>Este es un ejemplo claro de que existen pocas inversiones tan rentables y tangibles
                                como la ejecución de un programa <strong><i>premium</i></strong> de RI, esto sin
                                internalizar los beneficios
                                provenientes del lado del costo de la deuda.</p>
                            <p>Ahora supongamos en el ejercicio que la transición exitosa en el programa de RI de esta
                                emisora sea debida en un 50% a las soluciones efectivas brindadas por un asesor experto
                                como IRStrat en dos años de servicio, con un costo de US$250k, estaríamos hablando de un
                                Return on Investment (ROI), tan sólo en la parte “realizada” del beneficio (US$30
                                mills.), de 59.50 veces. Este ejercicio es una muestra muy contundente de las ventajas
                                de un programa premier de RI; constituyendo una ventaja competitiva en sí mismo.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="propuesta">
                <div className="container">
                    <h3 className="text-center">Claras evidencias</h3>
                    <div className="row strategy_info align-items-center mb-5">
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <p>Una gestión eficiente de RI conlleva una visibilidad apropiada, y una visibilidad
                                    apropiada a un mayor número de coberturas, y a su vez una mayor operatividad (medida
                                    en bursatilidad):</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img className="w-100  wow zoomIn" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                 src={cobertura_analisis} alt={" "}/>
                        </div>
                    </div>
                    <div className="row strategy_info align-items-center mb-5">
                        <div className="col-lg-5">
                            <img className="w-100  wow fadeIn" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                 src={free_float} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <p>Y a su vez, una mayor bursatilidad conlleva a la apertura del mercado,
                                    es decir a la existencia de precios competitivos y profundidad en volumen para
                                    robustas colocaciones, lo que en retorno genera un incremento en la operatividad; al
                                    haber un mayor floating en el mercado, en un círculo virtuoso generado por la
                                    ejecución de prácticas premier en RI.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row strategy_info align-items-center mb-5">
                        <div className="col-lg-12">
                            <p>Ejecutar sólidas prácticas en Relaciones con Inversionistas es sin duda un efectivo
                                método para ganar un firme posicionamiento en los mercados, y generar beneficios
                                tangibles en valuaciones y fuentes de financiamiento.</p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
